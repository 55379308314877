<script setup lang="ts">
// const props = defineProps<{}>()
// const emit = defineEmits<{}>()
// const { t } = useI18n({ useScope: 'local' })
// const { t: t } = useI18n({ useScope: 'global' })

const { appName } = useAppName()
</script>

<template>
  <div class="col-span-4">
    <div>{{ appName }} - De online plek om je stages te regelen</div>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "": ""
  },
  "nl": {
    "": ""
  }
}
</i18n>
